// import { amgApi } from "@/service/axios";

import axios from "@/axios";

class LoteService {
	async getLote(loteId) {
		const data = await axios.get(`/api/lote/${loteId}`);
		let posturas = data.data;
		posturas.sort((a, b) => (a.placa_madrilla > b.placa_madrilla ? 1 : -1));
		posturas.forEach((postura) => {
			postura.postura_id = { id: postura.id };
			postura.code = postura.postura_code;
		});
		return posturas;
	}
	async getMadrillasProcessing(postura_id = null) {
		const data = await axios.get("/api/postura/get-madrillas-processing", { params: { postura_id } });
		let posturas = data.data;
		posturas.sort((a, b) => (a.placa_madrilla > b.placa_madrilla ? 1 : -1));
		posturas.forEach((postura, index) => {
			postura.originalIndex = index;
			postura.total_eggs = postura.remaining_eggs;
			postura.postura_id = { id: postura.id };
		});
		return posturas;
	}
	async upsertLote({ lote, posturas }) {
		try {
			const { data } = await axios.post("/api/lote/upsert", { lote, posturas });
			return data;
		} catch (error) {
			throw error;
		}
	}
	async seeLoteNotification(notificationId) {
		return await axios.post("/api/lote/see-notification", { id: notificationId });
	}
}
export default new LoteService();
