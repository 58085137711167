<template>
	<div>
		<b-row>
			<b-col cols="12">
				<create-lote-general-information
					:lote="lote"
					:totalEggsInLote="totalEggsInLote"
					@saveLote="upsertLote"
				></create-lote-general-information>
			</b-col>
			<b-col md="6">
				<!-- POSTURAS -->
				<b-card>
					<b-card-title>
						<h3>Posturas</h3>
						<small v-if="posturas.length">Hacer doble click para pasar las posturas al lote</small>
					</b-card-title>
					<template v-if="posturas.length > 0">
						<transition-group tag="b-row" name="leave" type="transtion-group">
							<b-col
								lg="4"
								md="6"
								sm="4"
								xxl="3"
								cols="6"
								v-for="(postura, index) in posturas"
								:key="postura.p_id"
								class="mt-1"
							>
								<b-badge
									variant="light-primary"
									class="w-100 text-wrap h-100 text-left cursor-pointer"
									:style="`padding: 10px; color: ${
										layout.skin == 'dark' ? '#ffffff85' : '#0000008c'
									} !important`"
									@dblclick="addPosturaToLote(index, postura)"
								>
									<div
										class="text-center"
										:style="`background: ${
											layout.skin == 'dark' ? 'rgb(115 103 240 / 30%)' : 'rgb(255 255 255 / 99%)'
										} !important; padding: 9px !important;
											border-radius: 5px !important;`"
									>
										{{ postura.madrilla.plate }}
									</div>
									<br />
									<div style="margin: 10px 0; padding-left: 9px !important">
										P:
										{{ postura.padrillo.alias ? postura.padrillo.alias : postura.padrillo.plate }}
									</div>
									<div style="margin: 10px 0; padding-left: 9px !important">
										{{ postura.code }}
									</div>
									<div style="padding-left: 9px !important">
										Huevos:
										<br v-if="isNotLaptop" />
										<span
											class="remaining-eggs mr-2"
											:style="
												!isNotLaptop ? 'margin-left: 10px;' : 'margin-top: 5px; display:block'
											"
										>
											{{ postura.eggs_quantity }}
										</span>
										<feather-icon
											icon="Edit2Icon"
											class="text-warning cursor-pointer ml-2"
											@click="openToEditEggs(postura)"
										/>
									</div>
								</b-badge>
							</b-col>
						</transition-group>
					</template>
					<template v-else>Sin posturas, por favor ir a "Registro de huevos"</template>
				</b-card>
			</b-col>
			<b-col md="6" class="mt-sm-2 mt-md-0">
				<!-- LOTE  -->
				<b-card :title="titleLote">
					<transition-group tag="b-row" name="leave" type="transition-group">
						<b-col
							lg="4"
							md="6"
							sm="4"
							xxl="3"
							cols="6"
							v-for="(posturaLote, index) in posturasInOrder"
							:key="posturaLote.p_id"
							class="mt-1"
							style="padding-right: 10px; padding-left: 10px"
						>
							<b-badge variant="primary" class="w-100 text-wrap text-left h-100" style="padding: 10px">
								<span
									class="d-block text-center"
									style="
										background: rgb(71 63 153) !important;
										padding: 9px !important;
										border-radius: 5px !important;
									"
								>
									{{ posturaLote.madrilla.plate }}
								</span>
								<span class="d-block" style="margin: 10px 0; padding-left: 9px !important">
									P:
									{{
										posturaLote.padrillo.alias
											? posturaLote.padrillo.alias
											: posturaLote.padrillo.plate
									}}
								</span>
								<span class="d-block" style="margin: 10px 0; padding-left: 9px !important">
									{{ posturaLote.code }}
								</span>
								<span class="d-block" style="padding-left: 9px !important">
									Huevos:
									<br v-if="isNotLaptop" />
									<span
										class="remaining-eggs"
										:style="!isNotLaptop ? 'margin-left: 10px;' : 'margin-top: 5px; display:block'"
									>
										{{ posturaLote.eggs_quantity - posturaLote.total_eggs }}
									</span>
								</span>
								<b-button
									variant="gradient-danger"
									class="btn-icon rounded-circle closeButton"
									size="sm"
									@click="deletePosturaLote(index, posturaLote)"
									v-if="posturaLote.postura_finished != 1"
								>
									<feather-icon icon="XIcon" />
								</b-button>
								<spin-button-component
									:max="posturaLote.eggs_quantity"
									v-model="posturaLote.total_eggs"
									:disabled="posturaLote.postura_finished == 1"
								/>
							</b-badge>
						</b-col>
					</transition-group>
				</b-card>
			</b-col>
		</b-row>

		<EditEggs v-if="controllerToEditEggs" :postura="posturaObj" @hidden="closeToEditEggs" />
	</div>
</template>

<script>
import draggable from "vuedraggable"
import LoteService from "./service/lotes.service"
import SpinButtonComponent from "./components/SpinButtonComponent"
import CreateLoteGeneralInformation from "./components/CreateLoteGeneralInformation"
import EditEggs from "./modals/EditEggs.vue"
import moment from "moment"
export default {
	name: "LotesCreateDragAndDrop",
	async created() {
		await this.getMadrillasProcessing()
		if (this.$route.params.lote_id) await this.getLote(this.$route.params.lote_id)
	},
	components: {
		draggable,
		SpinButtonComponent,
		CreateLoteGeneralInformation,
		EditEggs,
	},
	computed: {
		breakpoint() {
			return this.$store.getters["app/currentBreakPoint"]
		},
		isLaptop() {
			return this.breakpoint === "xl"
		},
		isNotLaptop() {
			return this.breakpoint === "xs" || this.breakpoint === "md" || this.breakpoint === "lg"
		},
		totalEggsInLote() {
			return this.lote.posturas.reduce((total, postura) => {
				return total + postura.total_eggs
			}, 0)
		},
		titleLote() {
			return this.$route.params.lote_id
				? "Lote: " + (this.lote.posturas[0] ? this.lote.posturas[0]?.lote_code : "")
				: "Nuevo Lote"
		},
		posturasInOrder() {
			return this.lote.posturas.sort((a, b) => a.madrilla?.plate.localeCompare(b.madrilla?.plate))
		},
	},
	data() {
		return {
			posturas: [],
			lote: {
				placa_madrilla: null,
				date: moment().format("YYYY-MM-DD HH:mm:ss"),
				incubacionMethod: "artificial",
				posturas: [],
				created_by: null,
			},
			editPosturaRemainingEggsController: false,
			selectedPostura: null,
			controllerToEditEggs: false,
			posturaObj: {},
		}
	},
	methods: {
		async getMadrillasProcessing(postura_id = null) {
			this.isPreloading()
			const data = await LoteService.getMadrillasProcessing(postura_id)
			if (data.length == 1 && this.posturas) {
				if (postura_id) {
					let index = this.posturas.findIndex((p) => p.p_id == data[0].p_id)
					data[0].eggs_quantity = Number(data[0].eggs_quantity)
					this.$set(this.posturas, index, data[0])
				} else {
					this.posturas = data.map((item) => {
						return {
							...item,
							eggs_quantity: parseInt(item.eggs_quantity),
							total_eggs: 0,
						}
					})
				}
			} else {
				this.posturas = data.map((item) => {
					return {
						...item,
						eggs_quantity: parseInt(item.eggs_quantity),
						total_eggs: 0,
					}
				})
			}
			// agregar automaticamente las posturas finalizadas
			this.posturas.forEach((postura, idx) => {
				if (postura.postura_finished == 1) this.addPosturaToLote(idx, postura)
			})
			this.isPreloading(false)
		},
		async getLote() {
			this.isPreloading()
			const data = await LoteService.getLote(this.$route.params.lote_id)

			this.lote.posturas = data
			this.lote.date = data[0].date_lote
			for (let i = 0; i < this.lote.posturas.length; i++) {
				if (this.posturas.some((postura) => postura.p_id === this.lote.posturas[i].p_id)) {
					this.lote.posturas[i].originalIndex =
						this.posturas[
							this.posturas.findIndex((postura) => postura.p_id === this.lote.posturas[i].p_id)
						].originalIndex
					this.posturas.splice(
						this.posturas.indexOf(
							this.posturas.find((postura) => postura.p_id === this.lote.posturas[i].p_id)
						),
						1
					)
				}
			}
			this.isPreloading(false)
		},
		// GUARDAR BORRADOR O PROCESAR LOTE
		async upsertLote(status) {
			if (this.totalEggsInLote == 0) {
				this.showErrorSwal("Error", "Cantidad incorrecta", "Debes elegir al menos una postura")
				return
			}
			this.isPreloading()
			let lote = {
				placa_madrilla: this.lote.placa_madrilla,
				date: this.lote.date,
				created_by: this.currentUser.user_id,
				total_eggs: this.totalEggsInLote,
				status: status,
				fromMigration: false,
				lote_id: this.$route.params.lote_id ? this.$route.params.lote_id : null,
			}
			try {
				await LoteService.upsertLote({
					lote,
					posturas: this.lote.posturas,
				})
				this.showSuccessToast(
					`Se ha ${this.$route.params.lote_id ? "editado" : "registrado"} correctamente el lote!`
				)
				this.$router.push({ name: "lotes-sr" })
				this.isPreloading(false)
			} catch (error) {
				console.error(error)
				throw error
			}
		},
		addPosturaToLote(index, postura) {
			this.posturas.splice(index, 1)
			this.insertAt(this.lote.posturas, postura.originalIndex, postura)
		},
		insertAt(array, index, postura) {
			postura.total_eggs = postura.eggs_quantity
			array.splice(index, 0, postura)
		},
		onChangePosturasArray() {
			this.posturas.sort((a, b) => (a.originalIndex > b.originalIndex ? 1 : -1))
			this.lote.posturas.sort((a, b) => (a.originalIndex > b.originalIndex ? 1 : -1))
		},
		deletePosturaLote(index, postura) {
			if (this.$route.params.lote_id && this.posturas.length == 1) {
				this.showErrorSwal("Error", "Al menos debe haber una postura en el lote")
				return
			}
			this.lote.posturas.splice(index, 1)
			this.insertAt(this.posturas, postura.originalIndex, postura)
		},
		openToEditEggs(postura) {
			this.controllerToEditEggs = true
			this.posturaObj = postura
		},
		async closeToEditEggs(hasChanged) {
			this.controllerToEditEggs = false
			if (hasChanged) {
				await this.getMadrillasProcessing(this.posturaObj.p_id)
				this.posturaObj = {}
				if (this.$route.params.lote_id) await this.getLote(this.$route.params.lote_id)
			}
		},
	},
}
</script>
<style scoped>
.closeButton {
	display: block;
	position: absolute;
	top: -10px;
	right: -5px;
}
.leave-enter-active,
.leave-leave-active {
	transform-origin: 50% 50%;
	transition: transform 0.5s cubic-bezier(0.61, 1, 0.88, 1);
	transition-property: transform, opacity;
}
.leave-enter,
.leave-leave-to {
	transform: scale(0) rotate(-60deg);
}
</style>
