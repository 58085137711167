<template>
	<div
		class="d-flex align-items-center justify-content-between w-100"
		style="padding: 10px; border: 1px solid rgb(71, 63, 153); border-radius: 5px; margin-top: 10px"
	>
		<span
			class="cursor-pointer w-30 d-flex justify-content-center"
			@click="decrement()"
			style="width: 30%; border-right: 1px solid rgb(71, 63, 153)"
		>
			<feather-icon :class="{ 'text-muted': disabled }" icon="MinusIcon" size="20" />
		</span>
		<span class="w-30 d-flex justify-content-center" style="width: 30%">
			{{ mutableValue }}
		</span>
		<span
			class="cursor-pointer w-30 d-flex justify-content-center"
			@click="increment()"
			style="width: 30%; border-left: 1px solid rgb(71, 63, 153)"
		>
			<feather-icon :class="{ 'text-muted': disabled }" icon="PlusIcon" size="20" />
		</span>
	</div>
</template>

<script>
export default {
	props: {
		max: Number,
		value: {
			type: Number,
			default: 1,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			mutableValue: JSON.parse(this.value),
		}
	},
	methods: {
		increment() {
			if (this.disabled) return
			if (this.max) {
				if (this.mutableValue < this.max) {
					this.mutableValue++
					this.$emit("input", this.mutableValue)
				}
			} else {
				this.mutableValue++
				this.$emit("input", this.mutableValue)
			}
		},
		decrement() {
			if (this.disabled) return
			if (this.value > 1) {
				this.mutableValue--
				this.$emit("input", this.mutableValue)
			}
		},
	},
}
</script>

<style></style>
