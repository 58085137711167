<template>
	<b-modal
		ref="modal-edit-eggs"
		@hidden="$emit('hidden', hasChanged)"
		title="Editar huevos"
		no-close-on-backdrop
		centered
		cancel-title="Cancelar"
		size="lg"
		scrollable
	>
		<fieldset class="scheduler-border">
			<legend class="scheduler-border">{{ descriptionPostura }}</legend>
			<b-row class="mb-0 mt-1">
				<b-col cols="12" lg="4" class="mb-1">
					<b-form-group label="Fecha">
						<date-picker
							format="DD-MM-YYYY"
							v-model="current_date"
							valueType="format"
							:clearable="false"
						></date-picker>
					</b-form-group>
				</b-col>
				<b-col cols="12" lg="5" class="mb-1">
					<div class="d-flex justify-content-center align-items-center">
						<b-form-group label="Cantidad">
							<vue-number-input
								v-model="new_quantity"
								:min="1"
								inline
								controls
								style="max-width: 150px"
								class="text-center"
							/>
						</b-form-group>
						<b-button type="button" variant="primary" class="ml-1 btn-icon" @click="addInput">
							Agregar
							<feather-icon icon="PlusIcon" size="16" />
						</b-button>
					</div>
				</b-col>
				<b-col cols="12" lg="3" class="d-flex align-items-center justify-content-end">
					<h4 class="mb-0">Total: {{ totalEggs }}</h4>
				</b-col>
			</b-row>
		</fieldset>
		<b-row>
			<b-table-simple responsive sticky-header="calc(80vh - 200px)">
				<!-- <b-table-simple responsive sticky-header> -->
				<b-thead class="text-center">
					<b-tr>
						<b-th>#</b-th>
						<b-th>Fecha</b-th>
						<b-th>Registrados</b-th>
						<b-th>Disponibles</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="(input, index) in inputs" :key="`${index}-input`">
						<b-td>{{ index + 1 }}</b-td>
						<b-td>{{ input.register_date | myGlobalDayShort }}</b-td>
						<b-td>
							<div class="position-relative">
								<vue-number-input
									v-model="input.quantity"
									style="max-width: 150px"
									:min="input.stock - input.balance > 0 ? input.stock - input.balance : 1"
									inline
									controls
									class="text-center"
								/>
								<!-- se puede eliminar cuando no han sido editados -->
								<featherIcon
									v-if="input.quantity == input.balance"
									icon="TrashIcon"
									size="20"
									class="text-danger position-absolute cursor-pointer ml-1"
									style="margin-top: 5px"
									@click="deleteInput(input, index)"
								/>
							</div>
						</b-td>
						<b-td> {{ input.balance }}</b-td>
						<!-- <b-td>
							<b-badge :variant="input.status == 'DISPONIBLE' ? 'success' : 'danger'">
								{{ input.status }}
							</b-badge>
						</b-td> -->
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-row>

		<template #modal-footer="{ cancel }">
			<b-button @click="cancel()" variant="secondary"> Salir </b-button>
			<b-button variant="primary" @click="save()" v-if="!loading"> Guardar </b-button>
			<b-button variant="primary" disabled v-else>
				<b-spinner small />
				Cargando...
			</b-button>
		</template>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal.js";
import lotesService from "@/services/lotes.service";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
export default {
	name: "EditEggs",
	components: {
		DatePicker,
	},
	props: {
		postura: {
			type: Object,
			required: true,
		},
	},
	mixins: [modalMixin],
	computed: {
		descriptionPostura() {
			let postura = this.postura;
			return (
				postura.code +
				" / " +
				(postura.madrilla.alias ? postura.madrilla.alias + " - " : "") +
				postura.madrilla.plate +
				" / " +
				(postura.padrillo.alias ? postura.padrillo.alias + " - " : "") +
				postura.padrillo.plate
			);
		},
		totalEggs() {
			return this.inputs.reduce((total, item) => {
				return total + Number(item.balance);
			}, 0);
		},
		compCurrentDate() {
			return moment(moment(this.current_date, "DD-MM-YYYY")).format("YYYY-MM-DD");
		},
	},
	data() {
		return {
			inputs: [],
			cloneInputs: [],
			new_quantity: 0,
			current_date: moment().format("DD-MM-YYYY"),
			hasChanged: false,
			loading: false,
		};
	},
	async mounted() {
		await this.getEggs();
		this.toggleModal("modal-edit-eggs");
	},
	methods: {
		async getEggs() {
			this.isPreloading();
			const { data } = await lotesService.getEggsInLotes(this.postura.p_id);
			let inputs = data.map((d) => ({ stock: d.quantity, ...d }));
			this.inputs = inputs;
			this.cloneInputs = _.cloneDeep(inputs);
			this.isPreloading(false);
		},
		async deleteInput(input, index) {
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			await lotesService.deleteInputEggs({ egg_input_id: input.id });
			this.hasChanged = true;
			this.getEggs();
		},
		async addInput() {
			let data = {
				postura_id: this.postura.p_id,
				egg_id: this.postura.e_id,
				current_date: this.compCurrentDate,
				new_quantity: this.new_quantity,
			};
			await lotesService.addInputEggs(data);
			this.showSuccessToast("Nuevo registro agregado correctamente");
			this.hasChanged = true;
			this.getEggs();
		},
		async save() {
			this.loading = true;
			let dataInputs = [];
			this.inputs.forEach((input, index) => {
				if (input.quantity !== this.cloneInputs[index].quantity) {
					dataInputs.push(input);
				}
			});
			const { data } = await lotesService.saveInputEggs({ postura_id: this.postura.p_id, inputs: dataInputs });
			if (data) {
				this.showToast(
					"danger",
					"top-right",
					"Error",
					"AlertCircleIcon",
					"Algunos registros ya tienen salidas a un lote"
				);
			}
			this.showSuccessToast("Los registros han sido actualizados");
			this.loading = false;
			this.hasChanged = true;
			this.getEggs();
		},
		async cancel() {
			this.$emit("hidden", this.hasChanged);
		},
	},
};
</script>

<style lang="scss">
.number-input--controls > .number-input__input {
	text-align: center !important;
}
fieldset.scheduler-border {
	border: 1px groove #ddd !important;
	padding: 0 1.4em 1.4em 1.4em !important;
	margin: 0 0 1.5em 0 !important;
	-webkit-box-shadow: 0px 0px 0px 0px #000;
	box-shadow: 0px 0px 0px 0px #000;
}

legend.scheduler-border {
	font-size: 1.2em !important;
	font-weight: bold !important;
	text-align: left !important;
}
.mx-input-wrapper input {
	color: black;
}
</style>
