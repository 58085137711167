<template>
	<b-card>
		<b-row>
			<b-col cols="12" md="3">
				<b-form-group label="Fecha" label-for="date">
					<b-form-datepicker
						id="date"
						locale="es"
						:date-format-options="{
							year: 'numeric',
							month: 'numeric',
							day: 'numeric',
						}"
						placeholder="Fecha"
						v-model="lote.date"
					/>
				</b-form-group>
			</b-col>
			<b-col cols="12" md="3">
				<b-form-group label="Método" label-for="method">
					<b-form-radio-group style="margin-top: 12px" v-model="lote.incubacionMethod" :options="options" id="method" />
				</b-form-group>
			</b-col>
			<b-col cols="12" md="6" class="d-flex justify-content-center">
				<!-- class="d-flex justify-content-center flex-wrap align-items-center" -->
				<b-row class="w-100">
					<!-- <b-col
						cols="12"
						md="4"
						class="d-flex justify-content-center align-items-center"
					>
						<b-button
							v-ripple.400="'rgba(255, 255, 255, 0.15)'"
							variant="outline-primary"
							@click="$emit('saveLote', 1)"
							class="w-100"
						>
							<span>Guardar Borrador</span>
						</b-button>
					</b-col> -->
					<b-col cols="12" md="4" class="d-flex justify-content-center align-items-center">
						<b-button
							v-ripple.400="'rgba(255, 255, 255, 0.15)'"
							variant="primary"
							@click="$emit('saveLote', 2)"
							class="w-100"
						>
							<span>Procesar Lote</span>
						</b-button>
					</b-col>
					<b-col cols="12" md="4" class="d-flex justify-content-center align-items-center">
						Total: {{ totalEggsInLote }}
					</b-col>
				</b-row>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
import Ripple from "vue-ripple-directive";
export default {
	directives: {
		Ripple,
	},
	props: {
		lote: Object,
		totalEggsInLote: Number,
	},
	computed: {
		breakpoint() {
			return this.$store.getters["app/currentBreakPoint"];
		},
		isLaptop() {
			return this.breakpoint === "xl";
		},
		isNotLaptop() {
			return this.breakpoint === "xs" || this.breakpoint === "md" || this.breakpoint === "lg";
		},
	},
	data() {
		return {
			incubacionMethod: "artificial",
			options: [
				{ text: "Natural", value: "natural" },
				{ text: "Artificial", value: "artificial" },
			],
		};
	},
};
</script>

<style>
</style>
